import { Form, useLoaderData, useSubmit } from "@remix-run/react";
import logo from '../images/logoLight.png';
import { LoaderFunctionArgs, MetaFunction, redirect } from "@remix-run/cloudflare";
import { authenticator } from "~/utils/auth.server";

export const meta: MetaFunction = () => {
  return [
    { title: "Login | Oversite" },
    {
      property: "og:title",
      content: "Login",
    },
    {
      name: "description",
      content: "Login to your Oversite account",
    },
  ];
};

export async function loader({ context, params, request }:LoaderFunctionArgs) {
  let auth = authenticator({request, context})
  const isAuthenticated = await auth.isAuthenticated(request, context)
  // console.log('isAuthenticated::', isAuthenticated)
  if(isAuthenticated){
    return redirect('/company/dashboard')
  }else{
    return false
  }
}

export default function Login() {

  const loggedIn = useLoaderData()

    return (
      <>
      <div className="flex min-h-full flex-1 items-center justify-center px-4 py-12 sm:px-6 lg:px-8">
        <div className="w-full max-w-sm space-y-10 bg-white p-5 rounded drop-shadow">
          <div>
            <img
              className="mx-auto h-12 w-auto"
              src={logo}
              alt="Your Company"
            />
            <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
              Sign in to your account
            </h2>
          </div>
          <Form action="/auth/auth0" method="post">
            <button className="block mx-auto bg-primary-yellow text-white p-5 rounded hover:bg-hover-yellow">Click here to login</button>
          </Form>

          {/* <p className="text-center text-sm leading-6 text-gray-500">
            Don't have an account?{' '}
            <Form action="/auth/auth0?screen_hint=signup" method="post">
              <button className="font-bold text-blue-900 hover:text-blue-500">Click here to create one</button>
            </Form>
          </p> */}
        </div>
      </div>
      </>
    );
  }